export const simplePlural = (word: string, n?: number): string => {
  return n === 1 ? word : `${word}s`;
};

export const pluralHours = (shift: { time?: number } | undefined) => {
  return simplePlural("hr", shift?.time);
};

export const formatDigitWithLeadingZero = (digit: number): string => {
  return String(digit).padStart(2, "0");
};
