/**
 * Response class has helper functions to create a response object
 */
interface Response {
  code?: number;
  message?: string;
  data?: any;
}

enum ResponseCodes {
  Success = 0,
  InvalidRequestParams = -1,
  InternalServerError = 500,
}

export { Response, ResponseCodes };
