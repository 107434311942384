import { matchPath } from "react-router";

import { RouterPath } from "../routing/constant";

/**
 * @deprecated
 * React router is not built on absolute paths. Many routes are valid, but built
 * using the recommended guideline of:
 * `${match.url}/${WORKPLACE_REFERRALS_PATH}`
 * We should instead be verifying that the path is relative, and does not allow
 * https://cheatsheetseries.owasp.org/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet.html
 */
export function checkIfRouteIsValid(route: string) {
  const paths = [...Object.values(RouterPath)];

  for (const path of paths) {
    if (
      matchPath(route, {
        path,
        exact: false,
        strict: false,
      })
    ) {
      return true;
    }
  }
  return false;
}
