import { isPlatform } from "@ionic/react";
import { Adjust, AdjustEvent } from "@ionic-native/adjust";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";

import { USER_EVENTS } from "../../constants/userEvents";
import { triggerAdjustEventTracking } from "../store/session/api";

export enum AdjustEvents {
  BOOKED_SHIFT = "Worker Mobile: Shift Booked",
  COMPLETED_STRIPE_FLOW = "Worker Mobile: Stripe Flow Completed",
  ENTERED_LICENSE_INFORMATION = "Worker Mobile: Onboarding - License Info Entered",
  ENTERED_EMAIL = "Worker Mobile: Onboarding - Email Entered",
  ENTERED_FIRST_NAME = "Worker Mobile: Onboarding - First Name Entered",
  ENTERED_LAST_NAME = "Worker Mobile: Onboarding - Last Name Entered",
  ENTERED_ADDRESS = "Worker Mobile: Onboarding - Address Entered",
  ENTERED_SIGNIN_CREDENTIALS = "Worker Mobile: Sign In Credential Entered",
  ENTERED_SIGNUP_CREDENTIALS = "Worker Mobile: Sign Up Credential Entered",
  FINISHED_SUBMITTING_DOCUMENTS = "Worker Mobile: Submitting Documents Finished",
  STARTED_STRIPE_SET_UP = "Worker Mobile: Stripe Set Up Started",
  VIEWED_CONTRACTOR_BENEFITS = "Worker Mobile: Onboarding - Contractor Benefits Viewed",
  VIEWED_REFER_CO_WORKER = "Worker Mobile: Refer a Co-worker Viewed",
  PROCEEDED_TO_REQUIREMENTS_INFO = "Worker Mobile: Onboarding - Proceeded to Requirements Info",
  SIGNED_UP = "Worker Mobile: Signed Up",
  FIRST_OPEN = "First Open",
  ENTERED_APP = "Entered CBH App",
}

const getAdjustEventToken = {
  [AdjustEvents.BOOKED_SHIFT]: "ce5w2o",
  [AdjustEvents.COMPLETED_STRIPE_FLOW]: "z1x8g4",
  [AdjustEvents.ENTERED_LICENSE_INFORMATION]: "5wtprm",
  [AdjustEvents.ENTERED_APP]: "ka5ia5",
  [AdjustEvents.ENTERED_EMAIL]: "9vf6fx",
  [AdjustEvents.ENTERED_FIRST_NAME]: "g68gsp",
  [AdjustEvents.ENTERED_LAST_NAME]: "tndgxb",
  [AdjustEvents.ENTERED_ADDRESS]: "5y5b4e",
  [AdjustEvents.ENTERED_SIGNIN_CREDENTIALS]: "8mfj27",
  [AdjustEvents.ENTERED_SIGNUP_CREDENTIALS]: "w3rr77",
  [AdjustEvents.FINISHED_SUBMITTING_DOCUMENTS]: "ndq60r",
  [AdjustEvents.STARTED_STRIPE_SET_UP]: "4rdvb8",
  [AdjustEvents.VIEWED_CONTRACTOR_BENEFITS]: "u4wje0",
  [AdjustEvents.VIEWED_REFER_CO_WORKER]: "2a88cu",
  [AdjustEvents.PROCEEDED_TO_REQUIREMENTS_INFO]: "qi25qa",
  [AdjustEvents.SIGNED_UP]: "aw8pzo",
  [AdjustEvents.FIRST_OPEN]: "r5zzdb",
};

export const trackAdjustEvent = async (
  eventName: string,
  optionalProps?: Record<string, unknown>
): Promise<void> => {
  try {
    if (
      !isPlatform("capacitor") ||
      (!Object.values(AdjustEvents).includes(eventName as AdjustEvents) &&
        eventName !== USER_EVENTS.BOOK_INSTANTBOOK_SHIFT)
    ) {
      return;
    }
    const adjustEventName =
      eventName === USER_EVENTS.BOOK_INSTANTBOOK_SHIFT ? AdjustEvents.BOOKED_SHIFT : eventName;

    const { triggerAdjustEvent } = await triggerAdjustEventTracking();
    if (!triggerAdjustEvent) {
      return;
    }
    const eventToken = getAdjustEventToken[adjustEventName];
    const adjustEvent = new AdjustEvent(eventToken);
    for (const key in optionalProps) {
      adjustEvent.addCallbackParameter(key, optionalProps[key] as string);
      adjustEvent.addPartnerParameter(key, optionalProps[key] as string);
    }
    Adjust.trackEvent(adjustEvent);
  } catch (error) {
    deprecatedDoNotUseLogError({
      message: JSON.stringify((error as Error)?.stack),
    });
  }
};
