/**
 * HACK setTimeout when we want to change alert type without dismissing the modal
 * if we do it immediately, the ion-modal component will be stuck
 */
export const hackAlertChange = (fn: () => void) => {
  return (() => {
    setTimeout(() => {
      fn();
    }, 0);
  }) as unknown as () => void;
};
