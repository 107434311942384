import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Filesystem } from "@capacitor/filesystem";
import { Chooser } from "@ionic-native/chooser";
import { ActionType } from "@store/session";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { IAsyncState, useAsync } from "./useAsync";

const ALLOW_LISTED_ACCESS_DENIED_MESSAGES = {
  [CameraSource.Photos]: "User denied access to photos",
  [CameraSource.Camera]: "User denied access to camera",
};

interface CapturedPhotoType {
  /**
   * The base64 encoded string representation of the image.
   */
  file?: string;

  /**
   * The blob representation of the image.
   */
  fileBlob?: Blob;
  /**
   * The format of the image, ex: jpeg, png, gif.
   */
  type: string;
}

interface ICameraImageSizeConfig {
  width?: number;
  height?: number;
}

const dataURLToBlob = async (dataURL: string): Promise<Blob> => {
  return await fetch(dataURL).then((res) => res.blob());
};

const mimeTypeMap = {
  pdf: "application/pdf",
  jpg: "image/jpeg",
  png: "image/png",
  jpeg: "image/jpeg",
};

/**
 * Captures image from mobile
 * @param {number} quality defines the image quality for the captured picture
 * @returns
 */
const unsafeCapturePhoto = async (
  quality?: number,
  cameraSource: CameraSource = CameraSource.Prompt,
  cameraImageSizeConfig?: ICameraImageSizeConfig
): Promise<CapturedPhotoType> => {
  const fileData = await Camera.getPhoto({
    resultType: CameraResultType.DataUrl,
    source: cameraSource,
    ...(quality ? { quality } : {}),
    ...(cameraImageSizeConfig ? cameraImageSizeConfig : {}),
  });
  const file = fileData.dataUrl;
  const fileBlob = await dataURLToBlob(file!);
  return { file, fileBlob, type: fileData.format };
};

type CapturePhotoProps = {
  onCaptured?: (data?: CapturedPhotoType | null) => void;
  onError?: (error?: Error | null) => void;
};

const useCapturePhoto = (
  { onCaptured, onError }: CapturePhotoProps = {},
  data: CapturedPhotoType | null = null
) => {
  const dispatch = useDispatch();
  const {
    data: capturedFiles,
    status,
    error,
    run,
  } = useAsync<CapturedPhotoType>({
    status: "idle",
    data,
    error: null,
  });
  const statusRef = useRef<IAsyncState<CapturedPhotoType>["status"]>(status);

  useEffect(() => {
    if (statusRef.current !== status) {
      if (status === "resolved") {
        onCaptured?.(capturedFiles);
      } else if (status === "rejected") {
        let needAccessTo: CameraSource | null = null;
        switch (error?.message) {
          case ALLOW_LISTED_ACCESS_DENIED_MESSAGES[CameraSource.Photos]:
            needAccessTo = CameraSource.Photos;
            break;
          case ALLOW_LISTED_ACCESS_DENIED_MESSAGES[CameraSource.Camera]:
            needAccessTo = CameraSource.Camera;
            break;
        }
        if (needAccessTo) {
          dispatch({
            type: ActionType.SHOW_NEED_ACCESS_TO_TOAST,
            data: {
              needAccessTo,
            },
          });
        }
        onError?.(error);
      }
    }
    statusRef.current = status;
  }, [status, capturedFiles, error, onCaptured, onError, dispatch]);

  return {
    capturedFiles,
    error,
    status,
    capturePhoto: useCallback(
      (...args): void => {
        run(unsafeCapturePhoto(...args));
      },
      [run]
    ),
  };
};

/**
 * Opens file browser on IOS and android
 */
const mobileFileChooser = async (): Promise<CapturedPhotoType | void> => {
  const { uri, mediaType = "" } = (await Chooser.getFile("image/*,application/pdf")) || {};
  if (!uri) {
    return;
  }
  let type = mediaType?.split("/")?.pop() ?? "";

  if (!type) {
    type = uri?.split(".").pop() ?? "";
  }

  const mimeType = mediaType || mimeTypeMap[type] || type;

  if (!isMimeTypeSupported(mimeType)) {
    throw new Error("File's mime type not supported by the application.");
  }
  const fileContents = await Filesystem.readFile({
    path: uri,
  });
  const fileBase64 = "data:" + mimeType + ";base64," + fileContents?.data;
  const fileBlob = await dataURLToBlob(fileBase64);

  return { fileBlob: fileBlob, file: fileBase64, type };
};

const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

const readBlobAsBase64 = async (blob: Blob) => {
  return (await convertBlobToBase64(blob)) as string;
};

const isMimeTypeSupported = (type: string): boolean => {
  if (!type.includes("image/") && type !== "application/pdf") {
    return false;
  }
  return true;
};

export {
  mobileFileChooser,
  unsafeCapturePhoto,
  useCapturePhoto,
  readBlobAsBase64,
  dataURLToBlob,
  mimeTypeMap,
};
