import { formatRate } from "@src/app/hcpShifts/helper";

export interface Money {
  amount: number;
  currency: string;
}

export const formatMoney = (money: Money) => {
  const { amount, currency } = money;
  return `${currency === "USD" ? "$" : currency}${formatRate(amount)}`;
};
