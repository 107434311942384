export type UrlQueryStringReturnType = {
  [key in string]: string;
};
export const urlQueryString = (search?: string): UrlQueryStringReturnType => {
  if (!search && typeof search !== "string") {
    return {};
  }
  if (search[0] === "?") {
    search = search.slice(1);
  }
  return search.split("&").reduce((acc, cur) => {
    const parameter = cur.split("=");
    if (parameter.length !== 2) {
      return acc;
    }
    return {
      ...acc,
      [parameter[0]]: parameter[1],
    };
  }, {});
};
