import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import { jsPDF } from "jspdf";

import { isImage } from "./isImage";
import { SelectedFile } from "../shiftSignature/timecard/model";

/**
 * constructs a pdf from multiple image files
 * @param selectedImages base64 encoded images
 */
const constructPDFFromMultipleImages = async (
  selectedImages: SelectedFile[]
): Promise<{ fileBlob: Blob | undefined }> => {
  try {
    if (!selectedImages?.length) {
      return { fileBlob: undefined };
    }

    const documentPDFFile = new jsPDF({ compress: true });
    documentPDFFile.deletePage(1);
    for (const selectedImage of selectedImages) {
      if (!isImage(selectedImage.type)) {
        return { fileBlob: undefined };
      }
      const [width, height] = await new Promise<[number, number]>((resolve, reject) => {
        try {
          const htmlImageElement = new Image();
          htmlImageElement.onload = function () {
            // scale the image size to fit A4 size, assuming the maximum size
            // is 1080 * 1527.428571429, using the width of FHD resolution
            const scale = htmlImageElement.width > 1080 ? 1080 / htmlImageElement.width : 1;
            const widthInMM = (htmlImageElement.width * scale * 200) / 1080;
            const heightInMM = (htmlImageElement.height * scale * 287) / 1527.428571429;
            resolve([widthInMM, heightInMM]);
          };
          htmlImageElement.src = selectedImage.file;
        } catch (error) {
          reject(error);
        }
      });
      documentPDFFile
        .addPage()
        .addImage(selectedImage.file, selectedImage.type, 5, 5, width, height);
    }

    const fileBlob = documentPDFFile?.output("blob");
    return { fileBlob };
  } catch (error) {
    deprecatedDoNotUseLogError({
      message: `[constructPDFFromMultipleImages]: Unable to generate PDF file ${JSON.stringify(
        error?.stack
      )}`,
    });
    return { fileBlob: undefined };
  }
};

export { constructPDFFromMultipleImages };
